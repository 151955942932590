import { useState } from 'react';
import Swal from 'sweetalert2';

import {
  IContentUsersReportResponse,
  IFilters,
  IFiltersCourseAndSeries,
} from '../models/consumption';

type CallbackType = (params) => Promise<any>;

type FiltersParamsType = {
  limit: number;
  offset: number;
} & IFilters;

export default function useGetReport<T extends CallbackType>(callback: T) {
  const [loading, setLoading] = useState(false);

  const listConsumption = async (
    setState: React.Dispatch<React.SetStateAction<any[]>>,
    setRowCountState?: React.Dispatch<React.SetStateAction<number>>,
    filters?: FiltersParamsType,
  ) => {
    try {
      setLoading(true);
      const localConsumption = await callback(filters);
      const newObj = localConsumption.data.map(item => {
        if (item.content === null) {
          item.content = {
            content_id: null,
            title: null,
            type: null,
          };
        }

        return item;
      });

      setState(newObj);
      setRowCountState && setRowCountState(localConsumption.metaData.total);
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar a lista de consumo!',
        text: error.message || error,
        buttonsStyling: false,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getCourseAndSerieConsumption = async (
    setState: React.Dispatch<React.SetStateAction<IContentUsersReportResponse>>,
    setRowCountState?: React.Dispatch<React.SetStateAction<number>>,
    filters?: IFiltersCourseAndSeries,
  ) => {
    try {
      setLoading(true);
      const localData = await callback(filters);
      const manipulatedData = localData.contentUsersReport.data.map(
        (content, i) => ({
          ...content,
          row_id: `${i + 1}`,
        }),
      );

      setState({
        ...localData,
        contentUsersReport: {
          data: manipulatedData,
          metaData: localData.contentUsersReport.metaData,
        },
      });
      setRowCountState &&
        setRowCountState(localData.contentUsersReport.metaData.total);

      return localData;
    } catch (error: any) {
      const emptyObj: IContentUsersReportResponse = {
        contentUsersReport: {
          data: [],
          metaData: { limit: 25, offset: 1, total: 0 },
        },
        progressStatusTotal: {
          interested: 0,
          subscribed: 0,
          initiated: 0,
          in_progress: 0,
          finished: 0,
          canceled: 0,
        },
        reportCities: null,
        reportAge: [],
        reportGender: [],
        reportCnaes: [],
        reportCompanyAge: [],
        reportCompanySize: [],
        reportCompanyType: [],
        reportEducation: [],
        reportUfs: null,
      };

      setState(emptyObj);
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar os dados de consumo!',
        text: error.message || error,
        buttonsStyling: false,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
      });

      return emptyObj;
    } finally {
      setLoading(false);
    }
  };

  return { loading, listConsumption, getCourseAndSerieConsumption };
}

import axios from 'axios';
import Swal from 'sweetalert2';

import theme from '../styles/theme';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ApiKey: process.env.REACT_APP_SEBRAE_API_KEY,
  },
});

const pathname = window.location.pathname;
const routes = [
  '/content/article',
  '/content/podcast',
  '/content/research',
  '/reports/course-serie',
  'registration/author',
  '/synchronous/lesson',
  '/synchronous/modules',
  '/synchronous/course',
  '/lms/lessons',
  '/lms/modules',
  '/lms/courses',
  '/series-category/Episodes',
  '/series-category/seasons',
  '/users/list',
  '/users/moderator/list',
];

httpClient.interceptors.request.use(function (config) {
  const loading = document.getElementById('loading');

  if (loading && !routes.some(route => pathname.includes(route))) {
    loading.style.display = 'flex';
  }

  const token = window.localStorage.getItem('sebraeplay-api-token');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

httpClient.interceptors.response.use(
  function (response) {
    const loading = document.getElementById('loading');
    if (loading && !window.location.pathname.includes('/content/article')) {
      if (response.config.method !== 'GET') {
        loading.style.display = 'none';
      } else {
        setTimeout(() => {
          loading.style.display = 'none';
        }, 300);
      }
    }

    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao fazer a requisição!!',
        text: 'Seu token expirou, faça o login novmente.',
        confirmButtonColor: theme.colors.default,
      });

      window.location.href = `/login`;
    } else {
      setTimeout(() => {
        const loading = document.getElementById('loading');
        if (loading) {
          loading.style.display = 'none';
        }
      }, 300);

      return Promise.reject(error);
    }
  },
);

export default httpClient;
